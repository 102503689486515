import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { formatDate } from "../../utils/date";
import Tags from "../../components/tags/Tags";
import SortableHeaderColumn from "../../components/SortableHeader";
import { useColumnSort } from "../../hooks/useColumnSort";
import { sortSortableHeader } from "../../utils/sort";

import "./EventTable.css";
import { parseAsHTML } from "../../utils/string";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import { SortDirectionEnum } from "../../types/types";
import useAgencySettings from "../../hooks/useAgencySettings";

const DEFAULT_SORT = "occurred_at";
const DEFAULT_SORT_ORDER = SortDirectionEnum.DESCENDING;
const COL_SPAN = 5;

const EventTable = ({
    events,
    selectedEvent,
    setSelectedEvent,
    eventFilterType = null,
}: {
    events: any;
    selectedEvent: any;
    setSelectedEvent: any;
    eventFilterType: string | null;
}) => {
    const { constructEventUrl } = useMultiAgency();
    const { incidentText, incidentTextPlural, callTextPlural, callText } = useAgencySettings();

    const { sortColumn, sortDirection, setSort } = useColumnSort(DEFAULT_SORT, DEFAULT_SORT_ORDER);

    let sortedEvents = (Array.isArray(events) ? [...events] : []).sort(sortSortableHeader(sortColumn, true, sortDirection));

    return (
        <Table size="sm" className="radar-event-table w-100">
            <thead>
                <tr>
                    <th colSpan={COL_SPAN} className="text-muted font-weight-normal position-relative">
                        {sortedEvents && (
                            <small className="text-capitalize">
                                {eventFilterType !== null ? eventFilterType : "Merged Events"}: {sortedEvents.length || 0}
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip className="position-fixed" id="radar-active-people-tip">
                                            Merged events are deduplicated, consolidated {callTextPlural} and {incidentTextPlural}.
                                        </Tooltip>
                                    }
                                    placement="bottom"
                                    trigger={["hover", "focus", "click"]}
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                                </OverlayTrigger>
                            </small>
                        )}
                    </th>
                </tr>
                {/* ZIndex 10 to fix stacking issue caused by adding filter
                 style to the logo in Tags, it would overlap the TR labels text */}
                <tr className="fm-bg-color sticky-header" style={{ zIndex: 10 }}>
                    <SortableHeaderColumn
                        columnName="Date"
                        sortFn={setSort}
                        activeSortField={sortColumn}
                        activeSortDirection={sortDirection}
                        sortKey={DEFAULT_SORT}
                        defaultDirection={SortDirectionEnum.DESCENDING}
                    />
                    <SortableHeaderColumn
                        columnName={`${callText} Type`}
                        sortFn={setSort}
                        activeSortField={sortColumn}
                        activeSortDirection={sortDirection}
                        sortKey="call_type"
                        defaultDirection={SortDirectionEnum.ASCENDING}
                    />
                    <SortableHeaderColumn
                        columnName={`${incidentText} Type`}
                        sortFn={setSort}
                        activeSortField={sortColumn}
                        activeSortDirection={sortDirection}
                        sortKey="incident_type"
                        defaultDirection={SortDirectionEnum.ASCENDING}
                    />
                    <th>Labels</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {sortedEvents &&
                    sortedEvents.map((event: any) => {
                        const eventId = `${event._index}-${event._id}`;
                        const selectedEventId = selectedEvent && `${selectedEvent?._index}-${selectedEvent?._id}`;
                        const address = event?._source?.incident_address || event?._source?.respond_to_address;
                        const isSelectedEvent = selectedEvent && eventId === selectedEventId;
                        const textSum =
                            event.text_sums && event.text_sums[0] && event.text_sums[0]._source && event.text_sums[0]._source.summary;
                        const tags = event?.flags || event?._source?.flags;
                        const eventIsCall = event && event._index.includes("_calls");
                        const eventLink = event
                            ? eventIsCall
                                ? constructEventUrl(event?._source?.agency_id, event?._source?.call_number, undefined, undefined)
                                : constructEventUrl(event?._source?.agency_id, undefined, event?._source?.incident_number, undefined)
                            : undefined;

                        return (
                            <React.Fragment key={event.mergedKey || event._id}>
                                <tr
                                    className={`cursor-pointer pendo-radar-event-first-row main-row${isSelectedEvent ? " bg-info" : ""}`}
                                    onClick={() => setSelectedEvent(isSelectedEvent ? null : event)}
                                >
                                    <td className="text-nowrap">{formatDate(event?.occurred_at)}</td>
                                    <td className="text-nowrap">{event?.call_type}</td>
                                    <td className="text-nowrap">{event?.incident_type}</td>
                                    <td>
                                        <Tags tags={tags} />
                                    </td>
                                    <td className="text-center">
                                        <a href={eventLink} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
                                            <FontAwesomeIcon icon={faUpRightFromSquare} />
                                        </a>
                                    </td>
                                </tr>
                                <tr
                                    className={`pendo-radar-event-second-row cursor-pointer${textSum ? " main-row " : ""}${
                                        isSelectedEvent ? " bg-info" : ""
                                    }`}
                                    onClick={() => setSelectedEvent(isSelectedEvent ? null : event)}
                                >
                                    <td colSpan={COL_SPAN}>
                                        <small>{address}</small>
                                    </td>
                                </tr>
                                {textSum && (
                                    <tr
                                        className={`pendo-radar-event-third-row cursor-pointer summary-row${
                                            isSelectedEvent ? " bg-info" : ""
                                        }`}
                                        onClick={() => setSelectedEvent(isSelectedEvent ? null : event)}
                                    >
                                        <td colSpan={COL_SPAN}>
                                            <small>{parseAsHTML(textSum)}</small>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })}
            </tbody>
        </Table>
    );
};

export default EventTable;
