import {
    faCircleXmark,
    faInfoCircle,
    faPerson,
    faRadar,
    faRotateLeft,
    faSave,
    faTimes,
    faUpRightFromSquare,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { Accordion, Alert, Button, Card, Col, Container, Offcanvas, Row } from "react-bootstrap";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
// Radar filters
import { DateRangeFilter, IndexTypeFilter, SingleAgencyFilterButton, TagFilter, TimeRangeFilter } from "./filters/Filters";
// Radar Narratives
import NarrativesConnected from "./narratives/Narratives";

import { useSearchParams } from "react-router-dom";
import { useGetRadarQuery } from "../../api/api";
import EventTable from "./EventTable";
import InvolvedPeople from "./InvolvedPeople";
import TagsHistogram from "./TagsHistogram";
import CallNotesConnected from "./callnotes/CallNotes";
import RadarMap from "./RadarMap";
import { useTrackDimension } from "../../hooks/useTrackDimension";
import RadarSearch from "./RadarSearch";
import LoadingModal from "../../components/LoadingModal";
import { formatName } from "../../utils/person";
import { RadarConfigNameEnum } from "../../types/types";
// Radar Filters
import IncidentTypeFilter from "./filters/IncidentTypeFilter";
import CallTypeFilter from "./filters/CallTypeFilter";
import BeatsFilter from "./filters/BeatsFilter";
import RadarEventCard from "./RadarEventCard";
import RadarFirstClassFilter from "./RadarFirstClassFilter";

import "./Radar.css";
import RadarConfigModal from "./RadarConfigModal";
import usePermissions from "../../hooks/usePermissions";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import { collapseRows } from "./utils";
import useSingleAgencyFilter from "../../hooks/useSingleAgencyFilter";
import useAgencySettings from "../../hooks/useAgencySettings";
import { useFilters } from "../../hooks/useFilters";

const Radar = () => {
    useSetPageTitle("ForceMetrics | Radar");
    const { hasPermission } = usePermissions();
    const { resetFilters, filterPayload, beatsIsFetching } = useFilters({ isSingleAgencyMode: true });
    const [selectedEvent, setSelectedEvent] = React.useState<any>(null);
    const [showConfigModal, setShowConfigModal] = React.useState<boolean>(false);
    const [showWarningBanner, setShowWarningBanner] = React.useState<boolean>(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const { queryParams } = useSingleAgencyFilter();
    const { incidentTextPlural, callText, callTextPlural } = useAgencySettings();
    const dateRange = searchParams.get("dateRange");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const tags = searchParams.get("tags");
    const personId = searchParams.get("personId");
    const indexType = searchParams.get("indexType");

    const { constructEventUrl } = useMultiAgency();

    const { data: eventData, isFetching } = useGetRadarQuery({ ...queryParams, filters: filterPayload }, { skip: beatsIsFetching });
    let mergedEvents = eventData;
    if (eventData) {
        const updatedEvents = eventData?.events.map((e: any) => ({ ...e, ...e._source }));
        mergedEvents = collapseRows(updatedEvents);
    }

    const close = () => {
        document.body.click();
    };

    const divRef = useRef<HTMLDivElement | null>(null);
    const { width, height } = useTrackDimension(divRef, 0, 0);

    const selectedEventIsCall = selectedEvent && selectedEvent._index.includes("_calls");
    const selectedEventCallNumber = selectedEvent
        ? Array.isArray(selectedEvent._source.call_number)
            ? selectedEvent._source.call_number[0]
            : selectedEvent._source.call_number
        : "";
    const selectedEventLink = selectedEvent
        ? selectedEventIsCall
            ? constructEventUrl(selectedEvent?._source?.agency_id, selectedEvent?._source?.call_number, undefined, undefined)
            : constructEventUrl(selectedEvent?._source?.agency_id, undefined, selectedEvent?._source?.incident_number, undefined)
        : null;

    const selectedPerson =
        personId && eventData && eventData.involved_people && eventData.involved_people.find((p: any) => p.person_id === personId);

    const setSelectedEventFromMap = (event: any) => {
        if (!!event) {
            const key = !!event.incident_number ? "incident_number" : "call_number";
            const selectedEvent = mergedEvents && mergedEvents.find((e: any) => event[key] === e._source[key]);
            if (selectedEvent) {
                setSelectedEvent(selectedEvent);
            }
        }
    };

    const clearFilters = () => {
        resetFilters({ dateRange: "4days", view: RadarConfigNameEnum.ALL });
    };

    React.useEffect(() => {
        if (eventData?.total === 10000) {
            setShowWarningBanner(true);
        }
    }, [eventData]);

    return (
        <Container style={{ marginTop: "1em" }} className="radar-container pb-3" fluid>
            <h2 className="mb-4">
                <FontAwesomeIcon icon={faRadar} />
                &nbsp;Radar
            </h2>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <RadarFirstClassFilter close={close} clearFilters={clearFilters} />
                    <SingleAgencyFilterButton close={close} />
                    <DateRangeFilter close={close} dateRange={dateRange} startDate={startDate} endDate={endDate} />
                    <TimeRangeFilter close={close} />
                    <IndexTypeFilter
                        incidentTextPlural={incidentTextPlural}
                        callTextPlural={callTextPlural}
                        close={close}
                        indexType={indexType}
                    />
                    <BeatsFilter close={close} />
                    <TagFilter close={close} tags={tags} clearView />
                    <IncidentTypeFilter close={close} clearView />
                    <CallTypeFilter close={close} clearView />
                    <div className="vr mb-2 ms-2 me-2" style={{ minHeight: "2em", verticalAlign: "middle" }} />
                    <Button
                        variant="outline-secondary"
                        className="text-nowrap mb-2 pendo-radar-clear-filters"
                        size="sm"
                        onClick={clearFilters}
                    >
                        <FontAwesomeIcon icon={faRotateLeft} /> Clear Filters
                    </Button>
                    {hasPermission("radar_save_configs") && (
                        <Button
                            variant="primary"
                            className="text-nowrap mb-2 ms-2 pendo-radar-filter-selection-save-first"
                            size="sm"
                            onClick={() => setShowConfigModal(true)}
                        >
                            <FontAwesomeIcon icon={faSave} /> Save
                        </Button>
                    )}
                    {selectedPerson && (
                        <Button
                            data-testid="person-filter-button"
                            className="cursor-pointer fs-4 mb-2 ms-2 text-nowrap rounded-pill labels-filter pendo-radar-selected-person-clear"
                            variant="secondary"
                            size="sm"
                            onClick={() => {
                                let updatedSearchParams = new URLSearchParams(searchParams.toString());
                                updatedSearchParams.delete("personId");
                                setSearchParams(updatedSearchParams.toString());
                            }}
                        >
                            <FontAwesomeIcon icon={faPerson} />
                            &nbsp;{formatName(selectedPerson)}&nbsp;
                            <FontAwesomeIcon icon={faCircleXmark} size="sm" className="ms-1" />
                        </Button>
                    )}
                </div>
                <div style={{ minWidth: "30em" }}>
                    <RadarSearch />
                </div>
            </div>
            {eventData?.total === 10000 && showWarningBanner && (
                <Alert
                    className="fw-normal my-2 d-inline-flex gap-4 align-items-center"
                    variant="warning"
                    dismissible
                    onClose={() => setShowWarningBanner(false)}
                >
                    <div>
                        <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                        You've reached the maximum returned results of 10,000 records. Update or select additional filters to get a smaller
                        set of records.
                    </div>
                </Alert>
            )}
            <hr />
            <Row>
                <Col md={6}>
                    <Card className="fm-bg-color radar-event">
                        <Card.Body className="h-100">
                            <div className="d-flex">
                                <EventTable
                                    events={mergedEvents}
                                    selectedEvent={selectedEvent}
                                    setSelectedEvent={setSelectedEvent}
                                    eventFilterType={searchParams?.get("indexType") || null}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <div className="radar-right">
                        <Card className="radar-person">
                            <Card.Body className="h-100">
                                <div className="h-100 d-flex flex-column">
                                    <InvolvedPeople people={eventData && eventData.involved_people} eventCount={mergedEvents?.length} />
                                </div>
                            </Card.Body>
                        </Card>
                        <Row className="radar-viz" style={{ marginTop: ".5em" }}>
                            <Col sm={6}>
                                <Card className="overflow-hidden">
                                    <Card.Body>
                                        <TagsHistogram tags={eventData && eventData.flag_counts} isClickable={false} />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={6}>
                                <Card>
                                    <Card.Body className="p-0" ref={divRef} style={{ minHeight: "25em" }}>
                                        <RadarMap
                                            events={mergedEvents}
                                            height={height}
                                            width={width}
                                            setSelectedEventFromMap={setSelectedEventFromMap}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Offcanvas style={{ minWidth: "40%" }} show={!!selectedEvent} onHide={() => setSelectedEvent(null)} placement="end">
                <Offcanvas.Header>
                    <div className="d-flex flex-row-reverse justify-content-between" style={{ width: "100%" }}>
                        <div className="align-self-center">
                            <FontAwesomeIcon className="cursor-pointer" icon={faTimes} onClick={() => setSelectedEvent(null)} />
                        </div>
                        {!!selectedEventLink && (
                            <a href={selectedEventLink} target="_blank" rel="noreferrer">
                                <Button variant="primary" size="sm">
                                    <FontAwesomeIcon className="cursor-pointer" icon={faUpRightFromSquare} />
                                    &nbsp;Go To
                                </Button>
                            </a>
                        )}
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Accordion defaultActiveKey={["event", "callNotes", "narratives"]} alwaysOpen>
                        <Accordion.Item eventKey="event">
                            <Accordion.Header>Event Information</Accordion.Header>
                            <Accordion.Body className="px-0">
                                <RadarEventCard event={selectedEvent} />
                            </Accordion.Body>
                        </Accordion.Item>
                        {selectedEvent && selectedEvent._source.call_number && (
                            <Accordion.Item eventKey="callNotes">
                                <Accordion.Header>{callText} Notes</Accordion.Header>
                                <Accordion.Body>
                                    <CallNotesConnected
                                        callId={selectedEventCallNumber}
                                        modalOption={false}
                                        agencyId={selectedEvent._source.agency_id}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                        {selectedEvent && !selectedEventIsCall && (
                            <Accordion.Item eventKey="narratives">
                                <Accordion.Header>Narratives</Accordion.Header>
                                <Accordion.Body>
                                    <NarrativesConnected
                                        incidentId={selectedEvent._source.incident_number}
                                        modalOption={false}
                                        agencyId={selectedEvent._source.agency_id}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                    </Accordion>
                </Offcanvas.Body>
            </Offcanvas>
            <LoadingModal show={isFetching} />
            <RadarConfigModal show={showConfigModal} close={() => setShowConfigModal(false)} />
        </Container>
    );
};

export default Radar;
