import React from "react";
import { Alert, Button, Stack } from "react-bootstrap";
import { renderOrDash } from "../../../utils/display";
import { formatDate } from "../../../utils/date";
import Highlighter from "../../../components/highlighter/Highlighter";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { useMultiAgency } from "../../../hooks/useMultiAgency";
import AddressSearchModal from "../../../components/entity/AddressSearchModal";

const BaseCallDetails = ({ call, query, newTabLink }: { call?: any; query?: string; newTabLink?: boolean }) => {
    const { constructAddressUrl } = useMultiAgency();
    const [prefillSearch, setPrefillSearch] = React.useState("");

    const callAddressLink = call && constructAddressUrl(call?.source?.agency_id, call?.source, query);
    const callNumber = call?.highlights?.call_number ? call.highlights.call_number[0].text : call?.call_number || "";
    const callType = call?.highlights?.call_type ? call.highlights.call_type[0].text : call?.call_type || "";

    const address = !!call?.highlights?.full_address ? call.highlights.full_address[0].text : call?.full_address || "";

    const canViewMapboxSearchboxModal = window?.__RUNTIME_CONFIG__?.USE_LOCATION_SEARCH_MODAL === "true";

    return (
        <>
            {!!call && callType && callNumber ? (
                <Stack gap={3} direction="vertical">
                    <div>
                        <strong>ID</strong>
                        <div>
                            <Highlighter>{callNumber}</Highlighter>
                        </div>
                    </div>
                    <div>
                        <strong>Occurred At</strong>
                        <div>{formatDate(call?.occurred_at)}</div>
                    </div>
                    <div>
                        <strong>Type</strong>
                        <div>
                            <Highlighter>{callType}</Highlighter>
                        </div>
                    </div>
                    <div>
                        <strong>Received By</strong>
                        <div>{renderOrDash(call?.who_took_call)}</div>
                    </div>
                    <div>
                        <strong>Contact</strong>
                        {!!call?.caller_name && <div>{call?.caller_name}</div>}
                        {!call?.caller_name && !call?.contact_phone_number && <div>--</div>}
                    </div>
                    <div>
                        <strong>Address</strong>
                        {!address && <div data-testid="empty-call-address">--</div>}
                        {canViewMapboxSearchboxModal ? (
                            <>
                                <Button
                                    onClick={() => setPrefillSearch(address)}
                                    variant="link"
                                    className="p-0 d-flex flex-row justify-content-start text-start"
                                >
                                    <Highlighter>{address}</Highlighter>
                                </Button>
                                <AddressSearchModal
                                    show={prefillSearch !== ""}
                                    hideModalFn={() => setPrefillSearch("")}
                                    prefillSearch={prefillSearch}
                                />
                            </>
                        ) : (
                            address && (
                                <Link to={callAddressLink ? callAddressLink : ""} target={newTabLink ? "_blank" : ""}>
                                    <div className="d-flex flex-row justify-content-start">
                                        {newTabLink ? (
                                            <FontAwesomeIcon icon={faUpRightFromSquare} className="text-primary me-2 mt-1" />
                                        ) : null}
                                        <div>
                                            <Highlighter>{address}</Highlighter>
                                        </div>
                                    </div>
                                </Link>
                            )
                        )}
                    </div>
                </Stack>
            ) : (
                <Alert className="text-center" variant="secondary">
                    No Call
                </Alert>
            )}
        </>
    );
};

export default BaseCallDetails;
